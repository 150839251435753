import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Icon from "./Icon";

const Modal = ({
  visible,
  toggle,
  content,
  style,
  title,
  isLoading,
  onConfirm,
  onClose,
  confirmLabel,
  disableConfirm,
}) => {
  const [container] = useState(() => {
    return document.createElement("div");
  });

  useEffect(() => {
    container.classList.add("modal-portal");
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    const handleModalKeyDown = (e) => {
      // If pressed key is "Esc" and Modal is open:
      if (e.keyCode === 27 && visible) {
        // If onClose function is defined:
        if (onClose) {
          onClose(); // Trigger onClose function before closing
        }
        toggle(); // Toggle Modal closed
      }
    };

    // Add event listener:
    window.addEventListener("keydown", handleModalKeyDown);
    // Clean up.
    return () => {
      // Remove event listener:
      window.removeEventListener("keydown", handleModalKeyDown);
    };
  }, [onClose, toggle, visible]);

  return visible
    ? ReactDOM.createPortal(
        <div className="modal-wrapper">
          <div
            className="modal-content"
            style={{ ...style }}
            role="dialog"
            aria-modal="true"
          >
            <div className="grid-container halves">
              <div>
                {!isLoading && title && (
                  <>
                    <h3 style={{ fontWeight: "normal" }}>{title}</h3>
                    {/* <hr
                      style={{
                        color: "#ccc",
                        margin: "10px",
                      }}
                    /> */}
                  </>
                )}
              </div>
              {!isLoading ? (
                <Icon
                  icon="close"
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                    toggle();
                  }}
                  align="flex-end"
                  direction="row"
                />
              ) : (
                <></>
              )}
            </div>

            {content}
            {onConfirm !== undefined ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "auto",
                }}
              >
                <button
                  className={
                    disableConfirm
                      ? "btn no-hover btn-rounded"
                      : "btn btn-secondary btn-rounded"
                  }
                  disabled={disableConfirm}
                  onClick={() => {
                    onConfirm();
                    toggle();
                  }}
                >
                  {confirmLabel}
                </button>

                {/* <button className="btn btn-primary btn-rounded" onClick={toggle}>
                Close
              </button> */}
              </div>
            ) : null}
          </div>
          <div className="modal-backdrop"></div>
        </div>,
        container
      )
    : null;
};
export default Modal;
